var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box_1" },
    [
      _c(
        "div",
        { staticClass: "form_login" },
        [
          _vm._m(0),
          _c(
            "form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loginState,
                  expression: "loginState",
                },
              ],
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitLogin.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-input", {
                    staticClass: "inputText",
                    class: {
                      "is-invalid":
                        _vm.emailIsEmpty ||
                        _vm.checkEmailFormat ||
                        _vm.errorLogin ||
                        !_vm.userExistInDatabase,
                    },
                    attrs: { id: "login-input", placeholder: "Usuário" },
                    model: {
                      value: _vm.user,
                      callback: function ($$v) {
                        _vm.user = $$v
                      },
                      expression: "user",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.emailIsEmpty,
                          expression: "emailIsEmpty",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("Email é necessário")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.checkEmailFormat,
                          expression: "checkEmailFormat",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("O formato aceito é test@teste.com")]
                  ),
                  _vm.errorLogin && _vm.password
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("Usuário e/ou senha inválidos."),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.userExistInDatabase,
                          expression: "!userExistInDatabase",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("Usuário não cadastrado.")]
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-class": "label-class" } },
                [
                  _c("b-form-input", {
                    staticClass: "inputText",
                    class: {
                      "is-invalid":
                        (_vm.submitted && !_vm.password) || _vm.errorLogin,
                    },
                    attrs: {
                      id: "pass-input",
                      type: "password",
                      placeholder: "Senha",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.submitted && !_vm.password,
                          expression: "submitted && !password",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("Senha é necessária")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errorLogin && _vm.password,
                          expression: "errorLogin && password",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("Usuário e/ou senha inválidos.")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "submit-button" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.forgotPassState,
                              expression: "!forgotPassState",
                            },
                          ],
                          staticClass: "linkText",
                        },
                        [
                          _c(
                            "b-link",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onForgotPassClick()
                                },
                              },
                            },
                            [_vm._v("Esqueci minha senha")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "newMonitorButton2",
                          attrs: { type: "submit" },
                        },
                        [_vm._v("Entrar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.forgotPassState,
                  expression: "forgotPassState",
                },
              ],
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.requestNewPassowrd.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-for": "login-input",
                    "label-class": "label-class",
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "inputText",
                    class: {
                      "is-invalid": _vm.emailIsEmpty || _vm.checkEmailFormat,
                    },
                    attrs: { id: "login-input", placeholder: "Usuário" },
                    model: {
                      value: _vm.user,
                      callback: function ($$v) {
                        _vm.user = $$v
                      },
                      expression: "user",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.emailIsEmpty,
                          expression: "emailIsEmpty",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("Email é necessário")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.checkEmailFormat,
                          expression: "checkEmailFormat",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("O formato aceito é test@teste.com")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "submit-button" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "newMonitorButton",
                          staticStyle: { "padding-left": "20px" },
                          attrs: { type: "submit" },
                        },
                        [_vm._v("Enviar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.registerState,
                  expression: "registerState",
                },
              ],
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitRegister.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-for": "login-input",
                    "label-class": "label-class",
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "inputText",
                    class: {
                      "is-invalid": _vm.emailIsEmpty || _vm.checkEmailFormat,
                    },
                    staticStyle: { "font-size": "14px", height: "53px" },
                    attrs: { placeholder: "Usuário" },
                    on: {
                      seid: function ($event) {
                        _vm.login - _vm.input
                      },
                    },
                    model: {
                      value: _vm.user,
                      callback: function ($$v) {
                        _vm.user = $$v
                      },
                      expression: "user",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.emailIsEmpty,
                          expression: "emailIsEmpty",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("Email é necessário")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.checkEmailFormat,
                          expression: "checkEmailFormat",
                        },
                      ],
                      staticClass: "invalid-feedback",
                    },
                    [_vm._v("O formato aceito é test@teste.com")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "submit-button" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "newMonitorButton",
                          staticStyle: { "padding-left": "20px" },
                          attrs: { type: "submit" },
                        },
                        [_vm._v("Enviar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loginState,
                  expression: "!loginState",
                },
              ],
              staticClass: "linkText1",
            },
            [
              _c(
                "b-link",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onLoginClick()
                    },
                  },
                },
                [_vm._v("Tem usuário? Login")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "lineDivider" }),
          _c(
            "b-row",
            [
              _c(
                "b-button",
                {
                  staticClass: "loginSocial facebook",
                  attrs: { variant: "light" },
                  on: {
                    click: function ($event) {
                      return _vm.onLoginSocialClick("facebook")
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { "margin-left": "15px" },
                    attrs: {
                      src: require("@/assets/icons/face_home.svg"),
                      height: "22px",
                    },
                  }),
                  _vm._v(" Entrar com o Facebook "),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-button",
                {
                  staticClass: "loginSocial google",
                  attrs: { variant: "light" },
                  on: {
                    click: function ($event) {
                      return _vm.onLoginSocialClick("google")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/google_logo.svg"),
                      height: "20px",
                    },
                  }),
                  _vm._v(" Entrar com o Google "),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.registerState,
                  expression: "!registerState",
                },
              ],
              staticClass: "link-text",
              staticStyle: { "margin-left": "70px" },
            },
            [
              _c(
                "p",
                [
                  _vm._v("Novo usuário? "),
                  _c(
                    "b-link",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onRegisterClick()
                        },
                      },
                    },
                    [_vm._v("Crie uma conta!")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("Carousel"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "logo-container",
        attrs: { src: require("@/assets/images/LogoNovo-Retextoar-cinza.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }